


































































import { Vue, Component, Watch } from 'vue-property-decorator';
import FinishingAllianzProcessViewModel
  from '@/vue-app/view-models/components/contract-savings/allianz/finishing-allianz-process-view-model';

@Component({
  name: 'FinishingAllianzProcess',
  components: {
    SuccessfulAllianzProcess: () => import('@/vue-app/components/contract-savings/allianz/successful-allianz-process.vue'),
    AccountStatusErrorAllianzProcess: () => import('@/vue-app/components/contract-savings/allianz/account-status-error-allianz-process.vue'),
    ErrorAllianzProcess: () => import('@/vue-app/components/contract-savings/allianz/error-allianz-process.vue'),
  },
})
export default class FinishingAllianzProcess extends Vue {
  finishing_allianz_process_view_model = Vue.observable(
    new FinishingAllianzProcessViewModel(this),
  );

  @Watch('finishing_allianz_process_view_model.status.percent')
  onPercentChange(percent: number) {
    if (percent === 100) {
      this.$emit('loadingInfo', false);
    }
  }

  @Watch('finishing_allianz_process_view_model.status.has_problem')
  onStatusHasProblemChange() {
    this.$emit('loadingInfo', false);
    this.finishing_allianz_process_view_model.openIfRequiredAccountStatusErrorModal();
    this.finishing_allianz_process_view_model.openIfRequiredErrorModal();
  }

  created() {
    this.finishing_allianz_process_view_model.openIfRequiredAccountStatusErrorModal();
    this.finishing_allianz_process_view_model.openIfRequiredErrorModal();
  }
}
