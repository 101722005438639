import Vue from 'vue';
import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';

// Application
import { GetMyInvestmentAllianzOnBoardingStatusQuery }
  from '@/modules/my-investment/allianz/on-boarding-status/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class FinishingAllianzProcessViewModel {
  @Inject(TYPES.GET_MY_INVESTMENT_ALLIANZ_ON_BOARDING_STATUS_QUERY)
  private readonly get_my_investment_allianz_on_boarding_status_query!:
    GetMyInvestmentAllianzOnBoardingStatusQuery;

  private readonly i18n_namespace = 'components.contract-savings.allianz.finishing-allianz-process';

  private readonly view!: Vue;

  modals = {
    success: false,
    account_status_error: false,
    error: false,
  };

  get status() {
    return this.get_my_investment_allianz_on_boarding_status_query.execute();
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }

    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  finishOnBoarding = () => {
    this.modals.success = false;
    this.view.$emit('endProcess');
  }

  retryAccountStatusUpload = () => {
    this.modals.account_status_error = false;
    this.view.$emit('updateStep', 15);
  }

  openIfRequiredAccountStatusErrorModal = () => {
    if (this.status.has_problem && this.status.step_number === 1) {
      this.modals.account_status_error = true;
    }
  }

  openIfRequiredErrorModal = () => {
    if (this.status.has_problem && this.status.step_number !== 1) {
      this.modals.error = true;
    }
  }
}
